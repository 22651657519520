<template>
  <!-- 
    上传控件 
    用法：
     <upload-widget v-model="imgUrl"></upload-widget>
  -->
  <div class="clearfix">
    <a-upload :action="serverUrl + '/upload/upload'" list-type="text" :file-list="fileInfos" :headers="headers" @preview="handlePreview" @change="handleChange">
      <div v-if="fileInfos.length < maxUploadNum">
        <a-button> <a-icon type="upload" /> 点击此处上传附件 </a-button>
      </div>
    </a-upload>
    <!-- 图片预览 -->
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
/**把图片转成BASE64 */
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: 'UploadWidget',
  model: {
    prop: 'fileList',
    event: 'change',
  },
  props: {
    //最大上传数量
    maxUploadNum: {
      type: Number,
      default: 10,
    },
    /**父组件传入的文件列表 */
    fileList: {
      type: [Array, String],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      fileInfos: [],//组件操作的文件列表
      headers: {}, //头
      previewVisible: false,
      previewImage: '',
      // fileInfos: [], //上传文件
    };
  },
  created() {
    //父组件传入的文件列表传入
    this.fileInfos = this.fileList;
    // this.initVModelData();
    /**默认添加验证token */
    this.headers = {
      token: this.store.user.token,
      adminToken: this.store.admin.token,
    };
  },
  methods: {
   
    handleCancel() {
      this.previewVisible = false;
    },
    /**预览图 */
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.thumbUrl || file.preview;
      this.previewVisible = true;
    },
    /**图片上传成功 */
    handleChange(infos) {
      let imgArr = [];
      this.fileInfos = infos.fileList;
      for (let fl of infos.fileList) {
        if (fl.response != undefined) {
          imgArr.push({ id: -1, fileName: fl.name, path: fl.response.file.path });
        } else if (fl.url != undefined) {
          imgArr.push({ id: -1, path: fl.url });
        }
      }
      if (this.maxUploadNum == 1) {
        if (imgArr.length > 0) {
          this.$emit('change', imgArr[0]);
        } else {
          this.$emit('change', '');
        }
      } else {
        this.$emit('change', imgArr);
      }
    },
  },
  watch: {
    /**检测v-model数据是否发生改变 */
    // fileList(val) {
    // this.initVModelData();
    // },
  },
};
</script>

<style scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
